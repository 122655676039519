import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Column } from '../../../lib/data-set/column';
import { DataSource } from '../../../lib/data-source/data-source';

@Component({
  selector: 'fh-st-column-title',
  template: `
    <div class="ng2-smart-title">
      <fh-smart-table-title [source]="source" [column]="column" (sort)="sort.emit($event)"></fh-smart-table-title>
    </div>
  `,
})
export class ColumnTitleComponent {

  @Input()
  column!: Column;
  @Input()
  source!: DataSource;

  @Output() sort = new EventEmitter<any>();

}
