<div>
  @switch (getEditorType()) {
    @case ('list') {
      <select-editor
        [cell]="cell"
        [inputClass]="inputClass"
        (onClick)="onClick($event)"
        (onEdited)="onEdited($event)"
        (onStopEditing)="onStopEditing()">
      </select-editor>
    }
    @case ('textarea') {
      <textarea-editor
        [cell]="cell"
        [inputClass]="inputClass"
        (onClick)="onClick($event)"
        (onEdited)="onEdited($event)"
        (onStopEditing)="onStopEditing()">
      </textarea-editor>
    }
    @case ('checkbox') {
      <checkbox-editor
        [cell]="cell"
        [inputClass]="inputClass"
        (onClick)="onClick($event)">
      </checkbox-editor>
    }
    @case ('completer') {
      <completer-editor
        [cell]="cell">
      </completer-editor>
    }
    @default {
      <input-editor
        [cell]="cell"
        [inputClass]="inputClass"
        (onClick)="onClick($event)"
        (onEdited)="onEdited($event)"
        (onStopEditing)="onStopEditing()">
    </input-editor>
  }
}
</div>