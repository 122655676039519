<table [id]="tableId" [ngClass]="tableClass">

  @if (!isHideHeader || !isHideSubHeader) {
    <thead ng2-st-thead
      [grid]="grid"
      [isAllSelected]="isAllSelected"
      [source]="source"
      [createConfirm]="createConfirm"
      (create)="create.emit($event)"
      (selectAllRows)="onSelectAllRows($event)"
      (sort)="sort($event)"
      (filter)="filter($event)">
    </thead>
  }

  <tbody ng2-st-tbody 
    [grid]="grid"
    [source]="source"
    [deleteConfirm]="deleteConfirm"
    [editConfirm]="editConfirm"
    [rowClassFunction]="rowClassFunction"
    (edit)="edit.emit($event)"
    (delete)="delete.emit($event)"
    (custom)="custom.emit($event)"
    (userSelectRow)="onUserSelectRow($event)"
    (editRowSelect)="editRowSelect($event)"
    (multipleSelectRow)="multipleSelectRow($event)"
    (rowHover)="onRowHover($event)">
  </tbody>

</table>

@if (isPagerDisplay) {
  <fh-smart-table-pager
    [source]="source"
    [perPageSelect]="perPageSelect"
    (changePage)="changePage($event)">
  </fh-smart-table-pager>
}
