<div class="input-group">
  <mat-form-field>
    <input matInput
           [matDatepicker]="picker"
           [(ngModel)]="inputModel"
           placeholder="{{placeholder}}"
           [min]="min"
           [max]="max"
           (dateChange)="onChange($event)"
           readonly
           class="form-control">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>
