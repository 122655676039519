@for (row of grid.getRows(); track row) {
  <tr (click)="userSelectRow.emit(row)" (mouseover)="rowHover.emit(row)" class="ng2-smart-row" [className]="rowClassFunction(row)" [ngClass]="{selected: row.isSelected}">
    @if (isMultiSelectVisible) {
      <td class="ng2-smart-actions ng2-smart-action-multiple-select" (click)="multipleSelectRow.emit(row)">
        <input type="checkbox" class="form-control" [ngModel]="row.isSelected">
      </td>
    }
    @if (!row.isInEditing && showActionColumnLeft) {
      <td class="ng2-smart-actions">
        <fh-st-tbody-custom [grid]="grid" (custom)="custom.emit($event)" [row]="row" [source]="source"></fh-st-tbody-custom>
        <fh-st-tbody-edit-delete [grid]="grid"
          [deleteConfirm]="deleteConfirm"
          [editConfirm]="editConfirm"
          (edit)="edit.emit(row)"
          (delete)="delete.emit(row)"
          (editRowSelect)="editRowSelect.emit($event)"
          [row]="row"
          [source]="source">
        </fh-st-tbody-edit-delete>
      </td>
    }
    @if (row.isInEditing && showActionColumnLeft) {
      <td  class="ng2-smart-actions">
        <fh-st-tbody-create-cancel [grid]="grid" [row]="row" [editConfirm]="editConfirm"></fh-st-tbody-create-cancel>
      </td>
    }
    @for (cell of getVisibleCells(row.cells); track cell) {
      <td>
        <fh-smart-table-cell [cell]="cell"
          [grid]="grid"
          [row]="row"
          [isNew]="false"
          [mode]="mode"
          [editConfirm]="editConfirm"
          [inputClass]="editInputClass"
          [isInEditing]="row.isInEditing">
        </fh-smart-table-cell>
      </td>
    }
    @if (row.isInEditing && showActionColumnRight) {
      <td  class="ng2-smart-actions">
        <fh-st-tbody-create-cancel [grid]="grid" [row]="row" [editConfirm]="editConfirm"></fh-st-tbody-create-cancel>
      </td>
    }
    @if (!row.isInEditing && showActionColumnRight) {
      <td class="ng2-smart-actions">
        <fh-st-tbody-custom [grid]="grid" (custom)="custom.emit($event)" [row]="row" [source]="source"></fh-st-tbody-custom>
        <fh-st-tbody-edit-delete [grid]="grid"
          [deleteConfirm]="deleteConfirm"
          [editConfirm]="editConfirm"
          [row]="row"
          [source]="source"
          (edit)="edit.emit(row)"
          (delete)="delete.emit(row)"
          (editRowSelect)="editRowSelect.emit($event)">
        </fh-st-tbody-edit-delete>
      </td>
    }
  </tr>
}

@if (grid.getRows().length == 0) {
  <tr>
    <td [attr.colspan]="tableColumnsCount">
      {{ noDataMessage }}
    </td>
  </tr>
}
