/**
 * Extending object that entered in first argument.
 *
 * Returns extended object or false if have no target object or incorrect type.
 *
 * If you wish to clone source object (without modify it), just use empty new
 * object as first argument, like this:
 *   deepExtend({}, yourObj_1, [yourObj_N]);
 */
export const deepExtend = function(...objects: any[]): any {
  if (objects.length < 1 || typeof objects[0] !== 'object') {
    return false;
  }

  if (objects.length < 2) {
    return objects[0];
  }

  const target = objects[0];

  objects.slice(1).forEach((obj) => {
    if (typeof obj !== 'object' || Array.isArray(obj)) {
      return;
    }

    Object.keys(obj).forEach((key) => {
      const src = target[key];
      const val = obj[key];

      if (val === target) {
        return;
      } else if (typeof val !== 'object' || val === null) {
        target[key] = val;
      } else if (Array.isArray(val)) {
        target[key] = val.map((item) => (typeof item === 'object' && item !== null ? deepExtend({}, item) : item));
      } else if (typeof src !== 'object' || src === null || Array.isArray(src)) {
        target[key] = deepExtend({}, val);
      } else {
        target[key] = deepExtend(src, val);
      }
    });
  });

  return target;
};

export class Deferred {
  promise: Promise<any>;
  resolve: any;
  reject: any;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}

// getDeepFromObject({result: {data: 1}}, 'result.data', 2); // returns 1
export function getDeepFromObject(object = {}, name: string, defaultValue?: any) {
  const keys = name.split('.');
  let level: any = object;

  for (const key of keys) {
    if (level && typeof level === 'object' && key in level) {
      level = level[key];
    } else {
      return defaultValue;
    }
  }

  return level;
}

export function getPageForRowIndex(index: number, perPage: number): number {
  // we need to add 1 to convert 0-based index to 1-based page number.
  return Math.floor(index / perPage) + 1;
}
